import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'

import HeaderCenter from '../components/sections/headerCenter'
import ContactsWithImage from '../components/sections/contactsWithImage'

import { MailIcon, PhoneIcon, TagIcon } from '@heroicons/react/outline'

const services = [
	{
		name: 'Сайт на WordPress',
		href: '/website-on-wordpress',
		icon: TagIcon,
	},
	{
		name: 'Сайт на конструкторе',
		href: '/websitebuilder',
		icon: TagIcon,
	},
	{
		name: 'Комплексный сайт REG.Solutions',
		href: '//solutions.reg.ru/?rlink=reflink-6822007',
		icon: TagIcon,
	},
	{
		name: 'Сопровождение сайта',
		href: '/maintenance',
		icon: TagIcon,
	},
	// {
	// 	name: 'Консультация',
	// 	href: '/consultation',
	// 	icon: TagIcon,
	// },
]

const Contacts = () => (
	<Layout>
		<Seo title='Контакты' />
		<HeaderCenter
			uptitle='Контакты'
			title='Как с нами связаться'
			description='У нас все просто. Пиши в чат на сайте или на электронную почту. Мы отвечаем в течение 24 часов в рабочие дни.'
		/>
		<ContactsWithImage
			title='E-mail для всего'
			description='Отправляй сообщение на Email и мы тебе ответим.'
			image='../images/ContactPage06.jpg'
			imagealt='EnotWeb.ru'
			email='hello@enotweb.ru'
			phone=''
			phonetext=''
		>

			<div>
				<h2 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">Закажи то, что хочешь</h2>
				<div className="mt-3">
					<p className="text-lg text-gray-500">
						Наши услуги для тебя, легко и со вкусом.
					</p>
				</div>
				<div className="mt-9">

					{services.map((item) => (
						<div className="mt-6 flex">
							<div className="flex-shrink-0">
								<item.icon className="h-6 w-6 text-gray-400" aria-hidden="true" />
							</div>
							<div className="ml-3 text-base text-gray-500">
								<p>
									<Link
										key={item.name}
										to={item.href}
									>
										{item.name}
									</Link>
								</p>
							</div>
						</div>
					))}

				</div>
			</div>

		</ContactsWithImage>

	</Layout>
)

export default Contacts