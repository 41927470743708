import React from 'react'
import { MailIcon, PhoneIcon } from '@heroicons/react/outline'

import { StaticImage } from 'gatsby-plugin-image'

/*
  This example requires Tailwind CSS v2.0+
*/
function ContactsWithImage( { title, description, image, imagealt, email, phone, phonetext, children } ) {

  // Example:
  // {phone !== '' ? (
  //   <p>{phone}</p>
  // ) : null}

  return (
    <div className="relative bg-black-50">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          {/* <img
            className="h-56 w-full object-cover lg:absolute lg:h-full"
            src={image}
            alt={imagealt}
          /> */}
          <StaticImage
            className="h-56 w-full object-cover lg:absolute lg:h-full"
            src='../../images/ContactPage06.jpg'
            alt={imagealt}
            placeholder="blurred"
          />
        </div>
      </div>
      <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
        <div className="lg:pr-8">
          <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">

            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">{title}</h2>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
              {description}
            </p>

            <div className="mt-12 sm:mt-16 md:mt-0">
              <div className="mt-9">

                {phone != '' ? (
                <div className="flex">
                  <div className="flex-shrink-0">
                    <PhoneIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 text-base text-gray-500">
                    <p>{phone}</p>
                    <p className="mt-1">{phonetext}</p>
                  </div>
                </div>
                ) : null}

                <div className="mt-6 flex">
                  <div className="flex-shrink-0">
                    <MailIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 text-base text-gray-500">
                    <p><a href={`mailto:${email}`}>{email}</a></p>
                  </div>
                </div>

              </div>
            </div>

            <div className='mt-12 sm:mt-16 md:mt-16'>
              {children}
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

ContactsWithImage.defaultProps = {
  title: 'Давайте работать вместе',
  description: 'Отправьте нам сообщение на Email или в чат прямо на сайте',
  image: 'https://images.unsplash.com/photo-1556761175-4b46a572b786?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80',
  imagealt: 'Контакты Web-студии EnotWeb',
  email: 'support@example.com',
  phone: '+1 (555) 123 4567',
  phonetext: 'Пн-Пт 10 до 16 ч',
}

export default ContactsWithImage
